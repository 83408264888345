<template>
  <v-row justify="center">
    <v-dialog v-model="show" :persistent="false" :light="true" :fullscreen="true" :scrollable="false" overlay-color="tertiary" max-width="80%" class="pa-2 elevation-0" content-class="elevation-0">
      <base-material-card class="mt-6">
        <template #heading>
          <div class="display-2 font-weight-light text-uppercase">
            {{ product.id === undefined ? $t('admin.sub.products.form.create.title') : $t('admin.sub.products.form.edit.title') }}
          </div>
          <div class="subtitle-1 font-weight-light">
            {{ product.id === undefined ? $t('admin.sub.products.form.create.subtitle') : $t('admin.sub.products.form.edit.subtitle', {reference: product.reference}) }}
          </div>
        </template>

        <v-form ref="form" v-model="valid" lazy-validation @keyup.native.enter="validate() && submitProduct()">
          <v-container>
            <v-row dense>
              <v-col cols="12" sm="6" md="4">
                <v-select v-model="type" :items="productTypes" item-value="id" item-text="name" required chips dense
                          :label="$t('admin.sub.products.form.input.type.label')" :clearable="true"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-select v-model="collections" :items="productCollections" item-value="id" item-text="name" required multiple chips dense
                          :label="$t('admin.sub.products.form.input.collections.label')" :clearable="true"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-select v-model="categories" :items="productCategories" item-value="id" item-text="name" required multiple chips dense
                          :label="$t('admin.sub.products.form.input.categories.label')" :clearable="true"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="reference" :counter="referenceMaxLength" required dense :rules="referenceRules"
                              :label="$t('admin.sub.products.form.input.reference.label')" :clearable="true"
                />
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="name" :counter="nameMaxLength" required dense :rules="nameRules"
                              :label="$t('admin.sub.products.form.input.name.label')" :clearable="true"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-textarea v-model="summary" :counter="summaryMaxLength" dense :rules="summaryRules" auto-grow rows="1" row-height="15"
                            :label="$t('admin.sub.products.form.input.summary.label')" :clearable="true"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-textarea v-model="description" :counter="descriptionMaxLength" dense :rules="descriptionRules" auto-grow rows="1" row-height="15"
                            :label="$t('admin.sub.products.form.input.description.label')" :clearable="true"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="6" md="6">
                <v-select v-model="taxRates" :items="productTaxRates" item-value="rate" item-text="name" required multiple chips dense
                          :label="$t('admin.sub.products.form.input.taxes.label')" :clearable="true"
                />
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select v-model="tags" :items="productTags" item-value="id" item-text="name" required multiple chips dense
                          :label="$t('admin.sub.products.form.input.tags.label')" :clearable="true"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="6" md="6">
                <v-select v-model="groups" :items="productGroups" item-value="id" item-text="name" required multiple chips dense
                          :label="$t('admin.sub.products.form.input.groups.label')" :clearable="true"
                />
              </v-col>
              <v-col cols="12" sm="6" md="6" />
            </v-row>
            <edit-product-price-form :product="product" :tax-rates="taxRates" @update="updatePrices" />
            <edit-product-image-form :product="product" @update="updateImages" />
          </v-container>
          <v-card-actions>
            <v-spacer />
            <v-btn color="close" @click.stop="show=false">
              {{ $t('admin.sub.products.form.button.close') }}
            </v-btn>
            <v-btn :disabled="!valid || loading" :loading="loading" color="success" @click.stop="validate() && submitProduct()">
              {{ $t('admin.sub.products.form.button.save') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </base-material-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import { handleRequestError } from '@/services/common/Http';
  import EditProductPriceForm from '@/views/forms/product/EditProductPriceForm';
  import EditProductImageForm from '@/views/forms/product/EditProductImageForm';

  export default {
    name: 'EditProductForm',
    components: { EditProductPriceForm, EditProductImageForm },
    props: {
      productCollections: {
        type: Array,
        required: true
      },
      product: {
        type: Object,
        required: true
      },
      value: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        dialog: false,
        type: null,
        productTypes: [],
        collections: [],
        categories: [],
        productCategories: [],
        reference: '',
        referenceMinLength: 1,
        referenceMaxLength: 50,
        referenceRules: [
          v => !!v || this.$t('admin.sub.products.form.input.reference.rule.required'),
          v => (v && v.length >= this.referenceMinLength) || this.$t('admin.sub.products.form.input.reference.rule.minLength', { count: this.referenceMinLength }),
          v => (v && v.length <= this.referenceMaxLength) || this.$t('admin.sub.products.form.input.reference.rule.maxLength', { count: this.referenceMaxLength })
        ],
        name: '',
        nameMinLength: 1,
        nameMaxLength: 70,
        nameRules: [
          v => !!v || this.$t('admin.sub.products.form.input.name.rule.required'),
          v => (v && v.length >= this.nameMinLength) || this.$t('admin.sub.products.form.input.name.rule.minLength', { count: this.nameMinLength }),
          v => (v && v.length <= this.nameMaxLength) || this.$t('admin.sub.products.form.input.name.rule.maxLength', { count: this.nameMaxLength })
        ],
        summary: '',
        summaryMinLength: 0,
        summaryMaxLength: 280,
        summaryRules: [
          v => !v || v.length >= this.summaryMinLength || this.$t('admin.sub.products.form.input.summary.rule.minLength', { count: this.summaryMinLength }),
          v => !v || v.length <= this.summaryMaxLength || this.$t('admin.sub.products.form.input.summary.rule.maxLength', { count: this.summaryMaxLength })
        ],
        description: '',
        descriptionMinLength: 0,
        descriptionMaxLength: 5000,
        descriptionRules: [
          v => !v || v.length >= this.descriptionMinLength || this.$t('admin.sub.products.form.input.description.rule.minLength', { count: this.descriptionMinLength }),
          v => !v || v.length <= this.descriptionMaxLength || this.$t('admin.sub.products.form.input.description.rule.maxLength', { count: this.descriptionMaxLength })
        ],
        taxRates: [],
        productTaxRates: [
          { name: '5,5%', rate: 0.055 },
          { name: '10%', rate: 0.1 },
          { name: '20%', rate: 0.2 }
        ],
        tags: [],
        productTags: [
          { id: 'cannot_serve_alone', name: this.$t('admin.sub.products.form.input.tags.values.cannot_serve_alone') },
          { id: 'vegetarian', name: this.$t('admin.sub.products.form.input.tags.values.vegetarian') },
          { id: 'vegan', name: this.$t('admin.sub.products.form.input.tags.values.vegan') },
          { id: 'casher', name: this.$t('admin.sub.products.form.input.tags.values.casher') },
          { id: 'halal', name: this.$t('admin.sub.products.form.input.tags.values.halal') },
          { id: 'gluten_free', name: this.$t('admin.sub.products.form.input.tags.values.gluten_free') },
          { id: 'organic', name: this.$t('admin.sub.products.form.input.tags.values.organic') },
          { id: 'alcohol', name: this.$t('admin.sub.products.form.input.tags.values.alcohol') },
          { id: 'hot', name: this.$t('admin.sub.products.form.input.tags.values.hot') },
          { id: 'cold', name: this.$t('admin.sub.products.form.input.tags.values.cold') }
        ],
        prices: [],
        images: [],
        groups: [],
        productGroups: [],
        valid: true,
        loading: false
      };
    },
    computed: {
      show: {
        get () {
          return this.value;
        },
        set (value) {
          this.$emit('input', value);
        }
      }
    },
    watch: {
      product: async function () {
        if (this.product?.id === undefined) {
          this.type = null;
          this.collections = [];
          this.categories = [];
          this.reference = '';
          this.name = '';
          this.summary = '';
          this.description = '';
          this.taxRates = [];
          this.tags = [];
          this.prices = [];
          this.images = [];
          this.groups = [];
        } else {
          this.type = this.product?.type;
          this.collections = this.product?.collections === undefined ? [] : this.product?.collections.map((collection) => collection.id);
          this.categories = this.product?.categories === undefined ? [] : this.product?.categories.map((category) => category.id);
          this.reference = this.product?.reference;
          this.name = this.product?.name;
          this.summary = this.product?.summary;
          this.description = this.product?.description;
          this.taxRates = this.product?.taxRates;
          this.tags = this.product?.tags === undefined ? [] : this.product?.tags;
          this.prices = this.product?.prices;
          this.images = this.product?.images;
          this.groups = this.product?.groups === undefined ? [] : this.product?.groups.map((group) => group.id);
        }
      }
    },
    mounted: async function () {
      await this.refreshTypes();
      await this.refreshCategories();
      await this.refreshGroups();
    },
    methods: {
      async submitProduct () {
        this.loading = true;
        try {
          if (this.product?.id === undefined) {
            await this.$http.post(process.env.VUE_APP_BASE_API_URL + 'product', {
              typeId: this.type,
              collectionIds: this.collections,
              categoryIds: this.categories,
              reference: this.reference,
              name: this.name,
              summary: this.summary === null ? '' : this.summary,
              description: this.description === null ? '' : this.description,
              taxRates: this.taxRates,
              tags: this.tags,
              prices: this.prices,
              images: this.images,
              groupIds: this.groups
            });
          } else {
            await this.$http.put(process.env.VUE_APP_BASE_API_URL + 'product', {
              id: this.product?.id,
              typeId: this.type,
              collectionIds: this.collections,
              categoryIds: this.categories,
              reference: this.reference,
              name: this.name,
              summary: this.summary === null ? '' : this.summary,
              description: this.description === null ? '' : this.description,
              taxRates: this.taxRates,
              tags: this.tags,
              prices: this.prices,
              images: this.images,
              groupIds: this.groups
            });
          }
          this.$emit('success');
          this.show = false;
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('admin.sub.products.form.error.fail'));
        }
        this.loading = false;
      },
      async refreshTypes () {
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'product/types');
          if (response.data !== undefined && response.data.types !== undefined) {
            this.productTypes = response.data.types;
          }
        } catch (error) {
          await handleRequestError(error, this.$router);
        }
      },
      async refreshCategories () {
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'product/categories');
          if (response.data !== undefined && response.data.categories !== undefined) {
            this.productCategories = response.data.categories;
          }
        } catch (error) {
          await handleRequestError(error, this.$router);
        }
      },
      async refreshGroups () {
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'product/groups');
          if (response.data !== undefined && response.data.groups !== undefined) {
            this.productGroups = response.data.groups;
          }
        } catch (error) {
          await handleRequestError(error, this.$router);
        }
      },
      validate () {
        return this.$refs.form.validate();
      },
      updatePrices (prices) {
        this.prices = prices;
      },
      updateImages (images) {
        this.images = images;
      }
    }
  };
</script>
