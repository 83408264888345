<template>
  <div>
    <v-row dense>
      <v-col cols="12" class="text-h5">
        {{ $t('admin.sub.productsImages.title') }}
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-simple-table>
          <thead>
            <tr>
              <th>{{ $t('admin.sub.productsImages.table.position.title') }}</th>
              <th>{{ $t('admin.sub.productsImages.table.provider.title') }}</th>
              <th>{{ $t('admin.sub.productsImages.table.url.title') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="image in images" :key="image.id">
              <td>
                <v-text-field v-model="image.position" type="number" min="1" max="10" required dense solo hide-details :rules="priceRules"
                              :label="$t('admin.sub.productsImages.table.position.title')" :clearable="true" @change="updateImages()"
                />
              </td>
              <td>
                <v-select v-model="image.provider" :items="providers" :label="$t('admin.sub.productsImages.table.provider.title')" item-value="id" item-text="name" dense solo hide-details
                          @change="updateImages()"
                />
              </td>
              <td>
                <v-text-field v-model="image.url" type="text" required dense solo hide-details
                              :label="$t('admin.sub.productsImages.table.url.title')" :clearable="true" @change="updateImages()"
                />
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" class="text-right">
        <v-btn color="secondary" @click.stop="addImage()">
          <v-icon class="pr-2">
            mdi-plus
          </v-icon>
          {{ $t('admin.sub.productsImages.button.new') }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'EditProductImageForm',
    props: {
      product: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        images: [],
        providers: [],
        actions: [],
        priceRules: []
      };
    },
    watch: {
      product: function () {
        this.setupProduct();
      }
    },
    mounted () {
      this.providers = [];
      const providers = this.$i18n.t('admin.sub.productsImages.table.provider.values');
      Object.keys(providers).forEach((key) => {
        this.providers.push({ id: key.length === 0 ? null : key, name: providers[key] });
      });
      this.setupProduct();
    },
    methods: {
      addImage () {
        this.images.push({
          position: 1,
          provider: undefined,
          url: ''
        });
      },
      setupProduct () {
        if (this.product?.id === undefined) {
          this.images = [];
        } else {
          this.images = this.product.images === undefined ? [] : this.product.images;
        }
      },
      updateImages () {
        this.$emit('update', this.images);
      }
    }
  };
</script>
