<template>
  <div>
    <div v-for="type in ['buy', 'sell']" :key="type">
      <v-row dense>
        <v-col cols="12" class="text-h5">
          {{ $t('admin.sub.productsPrices.title.' + type) }}
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-simple-table>
            <thead>
              <tr>
                <th>{{ $t('location.title') }}</th>
                <th>{{ $t('admin.sub.productsPrices.table.provider.title') }}</th>
                <th>{{ $t('admin.sub.productsPrices.table.action.title') }}</th>
                <th>{{ $t('admin.sub.productsPrices.table.priceTaxExcluded.title') }}</th>
                <th>{{ $t('admin.sub.productsPrices.table.priceTaxIncluded.title') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="price in prices[type]" :key="price.id">
                <td>
                  <v-select v-model="price.location" :items="locations" :label="$t('location.title')" item-value="id" item-text="name" dense solo hide-details
                            @change="updatePrices()"
                  />
                </td>
                <td>
                  <v-select v-model="price.provider" :items="providers" :label="$t('admin.sub.productsPrices.table.provider.title')" item-value="id" item-text="name" dense solo hide-details
                            @change="updatePrices()"
                  />
                </td>
                <td>
                  <v-select v-model="price.action" :items="actions" :label="$t('admin.sub.productsPrices.table.action.title')" item-value="id" item-text="name" dense solo hide-details
                            @change="updatePrices()"
                  />
                </td>
                <td>
                  <v-text-field v-model="price.amount" type="number" required dense solo hide-details :rules="priceRules"
                                :label="$t('admin.sub.productsPrices.table.priceTaxExcluded.title')" :clearable="true" @change="updatePrices()"
                  />
                </td>
                <td>
                  <v-text-field :value="displayPriceTaxIncluded(price.amount)" type="number" required disabled dense solo hide-details :rules="priceRules"
                                :label="$t('admin.sub.productsPrices.table.priceTaxIncluded.title')" :clearable="true" @change="updatePrices()"
                  />
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" class="text-right">
          <v-btn color="secondary" @click.stop="addPrice(type)">
            <v-icon class="pr-2">
              mdi-plus
            </v-icon>
            {{ $t('admin.sub.productsPrices.button.new') }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
  import { applyTaxRates } from '@/util/PriceUtil';

  export default {
    name: 'EditProductPriceForm',
    props: {
      product: {
        type: Object,
        required: true
      },
      taxRates: {
        type: Array,
        required: true
      }
    },
    data () {
      return {
        prices: { buy: [], sell: [] },
        locations: this.$store.state.locations,
        providers: [],
        actions: [],
        priceRules: []
      };
    },
    watch: {
      product: function () {
        this.setupProduct();
      }
    },
    mounted () {
      this.providers = [];
      const providers = this.$i18n.t('admin.sub.productsPrices.table.provider.values');
      Object.keys(providers).forEach((key) => {
        this.providers.push({ id: key.length === 0 ? null : key, name: providers[key] });
      });
      this.actions = [];
      const actions = this.$i18n.t('admin.sub.productsPrices.table.action.values');
      Object.keys(actions).forEach((key) => {
        this.actions.push({ id: key.length === 0 ? null : key, name: actions[key] });
      });
      this.setupProduct();
    },
    methods: {
      addPrice (type) {
        switch (type) {
          case 'buy':
            this.prices.buy.push({
              type: type,
              currency: 'EUR'
            });
            break;
          case 'sell':
            this.prices.sell.push({
              type: type,
              currency: 'EUR'
            });
            break;
        }
      },
      displayPriceTaxIncluded (priceTaxExcluded) {
        return applyTaxRates(priceTaxExcluded, this.taxRates);
      },
      setupProduct () {
        if (this.product?.id === undefined) {
          this.prices = { buy: [], sell: [] };
        } else {
          this.prices.buy = [];
          this.prices.sell = [];
          this.prices.buy = this.product.prices.filter((price) => price.type === 'buy');
          this.prices.sell = this.product.prices.filter((price) => price.type === 'sell');
        }
      },
      updatePrices () {
        const prices = this.prices.sell.concat(this.prices.buy);
        this.$emit('update', prices);
      }
    }
  };
</script>
