<template>
  <v-container id="table-products" fluid tag="section">
    <alert-notification :message="alert" />
    <edit-product-form v-model="editProductForm" :product-collections="productCollections" :product="editedProduct" @success="refreshData" @fail="fail" />
    <v-row>
      <v-col cols="6" class="text-left">
        <v-select v-model="selectedCollectionIds" :items="productCollections" item-value="id" item-text="name" required chips multiple dense hide-details
                  :label="$t('admin.sub.products.form.input.collections.label')" :clearable="true" @change="refreshData"
        />
      </v-col>
      <v-col v-if="$can('create', 'Admin.Meta.Product')" cols="6" class="text-right">
        <v-btn color="secondary" :disabled="editProductForm" :loading="editProductForm" @click.stop="editedProduct={},editProductForm=true">
          <v-icon class="pr-2">
            mdi-food
          </v-icon>
          {{ $t('admin.sub.products.button.new') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-card>
      <v-card-title>
        {{ $t('admin.sub.products.title') }}
        <v-spacer />
        <v-text-field v-model="table.search" append-icon="mdi-magnify" :label="$t('admin.sub.products.table.search')" single-line hide-details />
        <v-spacer />
        <table-exporter :headers="table.headers" :items="products" />
        <table-printer element-id="table-products" />
        <v-btn icon medium dark color="black" :loading="loading" :disabled="loading" @click.stop="refreshData">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table :headers="table.headers" :options="table.options" :items="products" :search="table.search" :loading="loading">
        <template #[`item.type`]="{ item }">
          <span>{{ formatType(item.type) }}</span>
        </template>
        <template #[`item.categories`]="{ item }">
          <span>{{ formatCategories(item.categories) }}</span>
        </template>
        <template #[`item.taxRates`]="{ item }">
          <span>{{ formatTaxRates(item.taxRates) }}</span>
        </template>
        <template #[`item.buyPrice`]="{ item }">
          <span>{{ formatBuyPriceTaxExcluded(item.prices) }}<br>{{ formatBuyPriceTaxIncluded(item.taxRates, item.prices) }}</span>
        </template>
        <template #[`item.sellPriceShopify`]="{ item }">
          <span>{{ formatSellPriceTaxExcluded(item.prices, 'shopify') }}<br>{{ formatSellPriceTaxIncluded(item.taxRates, item.prices, 'shopify') }}</span>
        </template>
        <template #[`item.sellPriceUberEats`]="{ item }">
          <span>{{ formatSellPriceTaxExcluded(item.prices, 'ubereats') }}<br>{{ formatSellPriceTaxIncluded(item.taxRates, item.prices, 'ubereats') }}</span>
        </template>
        <template #[`item.sellPriceDeliveroo`]="{ item }">
          <span>{{ formatSellPriceTaxExcluded(item.prices, 'deliveroo') }}<br>{{ formatSellPriceTaxIncluded(item.taxRates, item.prices, 'deliveroo') }}</span>
        </template>
        <template #[`item.sellPriceJustEat`]="{ item }">
          <span>{{ formatSellPriceTaxExcluded(item.prices, 'justeat') }}<br>{{ formatSellPriceTaxIncluded(item.taxRates, item.prices, 'justeat') }}</span>
        </template>
        <template #[`item.groups`]="{ item }">
          <span>{{ formatGroups(item.groups) }}</span>
        </template>
        <template #[`item.images`]="{ item }">
          <span>{{ item.images.length }}</span>
        </template>
        <template #[`item.tags`]="{ item }">
          <span>{{ item.tags.length }}</span>
        </template>
        <template #[`item.updatedAt`]="{ item }">
          <span>{{ formatDate(item.updatedAt) }}</span>
        </template>
        <template #[`item.actions`]="{ item }">
          <span v-if="$can('update', 'Admin.Meta.Product')">
            <v-icon medium @click="cloneProduct(item)">
              mdi-file-multiple-outline
            </v-icon>
            <v-icon class="ml-2" medium @click="editedProduct=item,editProductForm=true">
              mdi-pencil
            </v-icon>
            <v-icon class="ml-2" medium @click="archiveProduct(item)">
              mdi-archive
            </v-icon>
          </span>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
  import { displayDateISO } from '@/util/DateUtil';
  import { applyTaxRates } from '@/util/PriceUtil';
  import { handleRequestError } from '@/services/common/Http';
  import AlertNotification from '@/views/components/common/notification/AlertNotification';
  import TableExporter from '@/views/components/common/TableExporter';
  import TablePrinter from '@/views/components/common/TablePrinter';
  import EditProductForm from '@/views/forms/product/EditProductForm';

  export default {
    name: 'Products',
    components: { AlertNotification, EditProductForm, TableExporter, TablePrinter },
    data () {
      return {
        alert: '',
        editedProduct: {},
        editProductForm: false,
        loading: false,
        table: {
          headers: [
            {
              text: this.$i18n.t('admin.sub.products.table.column.type'),
              align: 'center',
              filterable: true,
              sortable: false,
              value: 'type'
            },
            {
              text: this.$i18n.t('admin.sub.products.table.column.categories'),
              align: 'center',
              filterable: true,
              sortable: false,
              value: 'categories'
            },
            {
              text: this.$i18n.t('admin.sub.products.table.column.reference'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'reference'
            },
            {
              text: this.$i18n.t('admin.sub.products.table.column.name'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'name'
            },
            {
              text: this.$i18n.t('admin.sub.products.table.column.taxes'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'taxRates'
            },
            {
              text: this.$i18n.t('admin.sub.products.table.column.buyPrice'),
              align: 'center',
              filterable: false,
              sortable: false,
              value: 'buyPrice'
            },
            {
              text: this.$i18n.t('admin.sub.products.table.column.sellPriceShopify'),
              align: 'center',
              filterable: false,
              sortable: false,
              value: 'sellPriceShopify'
            },
            {
              text: this.$i18n.t('admin.sub.products.table.column.sellPriceUberEats'),
              align: 'center',
              filterable: false,
              sortable: false,
              value: 'sellPriceUberEats'
            },
            {
              text: this.$i18n.t('admin.sub.products.table.column.sellPriceDeliveroo'),
              align: 'center',
              filterable: false,
              sortable: false,
              value: 'sellPriceDeliveroo'
            },
            {
              text: this.$i18n.t('admin.sub.products.table.column.sellPriceJustEat'),
              align: 'center',
              filterable: false,
              sortable: false,
              value: 'sellPriceJustEat'
            },
            {
              text: this.$i18n.t('admin.sub.products.table.column.groups'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'groups'
            },
            {
              text: this.$i18n.t('admin.sub.products.table.column.images'),
              align: 'center',
              filterable: false,
              sortable: true,
              value: 'images'
            },
            {
              text: this.$i18n.t('admin.sub.products.table.column.tags'),
              align: 'center',
              filterable: false,
              sortable: true,
              value: 'tags'
            },
            {
              text: this.$i18n.t('admin.sub.products.table.column.updatedAt'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'updatedAt'
            },
            {
              text: '',
              align: 'center',
              filterable: false,
              sortable: false,
              value: 'actions',
              width: '120px'
            }
          ],
          options: {
            page: 1,
            itemsPerPage: -1,
            sortBy: ['updatedAt'],
            sortDesc: [true],
            groupBy: [],
            groupDesc: [],
            multiSort: false,
            mustSort: true
          },
          search: ''
        },
        selectedCollectionIds: [],
        productCollections: [],
        products: []
      };
    },
    mounted: async function () {
      await this.refreshCollections();
      await this.refreshData();
    },
    methods: {
      formatType (type) {
        if (type === undefined) {
          return '';
        }

        return type.name;
      },
      formatCategories (categories) {
        if (categories === undefined || categories.length === 0) {
          return '';
        }

        return categories.map((category) => category.name).join(', ');
      },
      formatTaxRates (taxRates) {
        if (taxRates === undefined || taxRates.length === 0) {
          return '';
        }

        return taxRates.map((rate) => String(rate * 100) + '%').join(', ');
      },
      formatBuyPriceTaxExcluded (prices) {
        if (prices === undefined || prices.length === 0) {
          return '';
        }

        const price = prices.find((price) => price.type === 'buy' && price.location === undefined);
        if (price === undefined) {
          return '';
        }

        return String(price.amount) + this.$i18n.t('common.price.currency.' + String(price.currency) + '.symbol') + ' ' + this.$i18n.t('common.price.taxExcluded.symbol');
      },
      formatBuyPriceTaxIncluded (taxRates, prices) {
        if (prices === undefined || prices.length === 0) {
          return '';
        }

        const price = prices.find((price) => price.type === 'buy' && price.location === undefined);
        if (price === undefined) {
          return '';
        }

        return String(applyTaxRates(price.amount, taxRates)) + this.$i18n.t('common.price.currency.' + String(price.currency) + '.symbol') + ' ' + this.$i18n.t('common.price.taxIncluded.symbol');
      },
      formatSellPriceTaxExcluded (prices, provider) {
        if (prices === undefined || prices.length === 0) {
          return '';
        }

        const price = prices.find((price) => price.type === 'sell' && price.location === undefined && price.provider === provider);
        if (price === undefined) {
          return '';
        }

        return String(price.amount) + this.$i18n.t('common.price.currency.' + String(price.currency) + '.symbol') + ' ' + this.$i18n.t('common.price.taxExcluded.symbol');
      },
      formatSellPriceTaxIncluded (taxRates, prices, provider) {
        if (taxRates === undefined || taxRates.length === 0 || prices === undefined || prices.length === 0) {
          return '';
        }

        const price = prices.find((price) => price.type === 'sell' && price.location === undefined && price.provider === provider);
        if (price === undefined) {
          return '';
        }

        return String(applyTaxRates(price.amount, taxRates)) + this.$i18n.t('common.price.currency.' + String(price.currency) + '.symbol') + ' ' + this.$i18n.t('common.price.taxIncluded.symbol');
      },
      formatGroups (groups) {
        if (groups === undefined || groups.length === 0) {
          return '';
        }

        return groups.map((group) => group.name).join(', ');
      },
      formatDate: displayDateISO,
      async archiveProduct (product) {
        if (confirm(this.$i18n.t('admin.sub.products.form.confirmation.archive', { name: product.name }))) {
          this.loading = true;
          try {
            await this.$http.put(process.env.VUE_APP_BASE_API_URL + 'product/status', { productId: product.id, status: 'archived' });
            this.alert = '';
          } catch (error) {
            this.alert = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('admin.error.fail'));
          }
          this.loading = false;

          await this.refreshData();
        }
      },
      async cloneProduct (product) {
        if (confirm(this.$i18n.t('admin.sub.products.form.confirmation.clone', { name: product.name }))) {
          this.loading = true;
          try {
            await this.$http.post(process.env.VUE_APP_BASE_API_URL + 'product/clone', { productId: product.id });
            this.alert = '';
          } catch (error) {
            this.alert = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('admin.error.fail'));
          }
          this.loading = false;

          await this.refreshData();
        }
      },
      async refreshData () {
        this.loading = true;
        try {
          const collections = this.selectedCollectionIds.map((collectionId) => 'collections[]=' + String(collectionId)).join('&');
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'products/?' + collections, { timeout: 60000 });
          if (response.data !== undefined && response.data.products !== undefined) {
            this.products = response.data.products;
          }
          this.alert = '';
        } catch (error) {
          this.alert = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('admin.error.fail'));
        }
        this.loading = false;
      },
      async refreshCollections () {
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'product/collections');
          if (response.data !== undefined && response.data.collections !== undefined) {
            if (Array.isArray(response.data.collections) && response.data.collections.length > 0) {
              this.selectedCollectionIds = [response.data.collections[0].id];
            }
            this.productCollections = response.data.collections;
          }
        } catch (error) {
          await handleRequestError(error, this.$router);
        }
      },
      async fail (error, message) {
        this.alert = await handleRequestError(error, this.$router, this.$i18n, message);
      }
    }
  };
</script>
